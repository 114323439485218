<template>
  <div style="display: flex;justify-content: center;padding-top: 20vh;">
    <el-form ref="form" :model="form" label-width="120px" >
      <el-form-item label="项目名:">
        <el-select v-model="form.name" class="select" size="medium" clearable placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="系统:">
        <el-radio-group v-model="form.system" @change="aaa">
          <el-radio :label="1">andriod</el-radio>
          <el-radio :label="2">ios</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="版本号:">
        <el-input-number v-model="form.num_1"  :min="1" :max="999" :controls="false" ></el-input-number>
        <el-input-number v-model="form.num_2"  :min="0" :max="999" :controls="false" ></el-input-number>
        <el-input-number v-model="form.num_3"  :min="0" :max="999" :controls="false" ></el-input-number>
      </el-form-item>
      <el-form-item label="更新类型:">
        <el-radio-group  v-model="form.isForce">
          <el-radio v-if="form.system== 1" :label="1">整包更新</el-radio>
          <el-radio v-if="form.system== 1 || form.system== 2" :label="2">热更新</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import md5 from 'js-md5'
  import axios from 'axios'
  export default {
    data() {
      return {
        form: { //提交的表单form
          isForce: 1, //1整包更新2热更新
          system : 1,
          name  : '',
        },
        options: [{
          value: 'syg_app',
          label: '视云格APP',
		  account: 'syg_app147258369'
        }, {
          value: 'optometry',
          label: '电脑验光仪',
		  account: 'optometry147258369'
        }, {
          value: 'af',
          label: '安防系统',
		  account: 'af147258369'
        }, {
          value: 'vision',
          label: '视力检测',
		  account: 'vision147258369'
        }, {
          value: 'im_student',
          label: '音视频系统',
		  account: 'im_student147258369'
        }],

      }
    },
    methods: {
      onSubmit() { //提交按钮
        if (this.form.name =="") {
          this.$message.error('请选择项目');
        } else if(this.form.num_1 == undefined || this.form.num_2 ==undefined || this.form.num_3 == undefined){
          this.$message.error('版本号必须 填写');
        }else {
			const appItem = this.options.find(item=>item.value == this.form.name)
            const data  = {
              app_name      : this.form.name,
              os            : this.form.system == 1 ? 'android':'ios',
              type          : this.form.isForce,
              version       : this.form.num_1+'.'+this.form.num_2+'.'+this.form.num_3,
              auth_account  : appItem.account,
              auth_pass     : 'e10adc3949ba59abbe56e057f20f883e'
            };
			
						axios.post('https://version.shiyuntech.net/v1/version/release',data)
            // axios.post('http://version.dljianwei.com/v1/version/release',data)
              .then(res => {
                if(res.data.status.code == 1){
                    this.$message.success('发布成功');
                    this.$router.push({ // 页面跳转
                      name: 'versionList', // 往这个页面跳转
                    })
                }else{
                    this.$message.error(res.data.status.msg);
                }

              })
              .catch(err => {
                console.log(err)
              })
        }
      },
      change(res) { //上传文件成功回调
        if (res.status.code == 1) {
          this.$message.success("提交成功");
          this.form = {};
          this.file = [];
        } else {
          this.$message.error(res.status.msg);
        }
      },
      aaa(){
        if(this.form.system == 2){
          this.form.isForce  = 2
        }else{
          this.form.isForce  = 1
        }
      }
    }
  }
</script>

<style scoped>
  .file {
    display: flex;
    justify-content: center;
  }
</style>
